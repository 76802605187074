.checkbox {
  padding-left: 22px;
  height: 22px;
}
.checkbox label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
  font-weight: normal;
  padding-left: 10px;
}

.checkbox label::after {
  background: none;
  border: 1px solid #cecece;
  color: #555555;
  display: inline-block;
  font-size: 20px;
  height: 22px;
  left: -22px;
  line-height: 22px;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: -3px;
  width: 22px;
	content:"";
}
.checkbox-white label::after {
  background-color: #ffffff;
}
.checkbox input[type="checkbox"] {
  opacity: 0;
}
.checkbox input[type="checkbox"]:checked + label::after {
  content: "";
  font-family: "FontAwesome";
	background: #c6aa76 none repeat scroll 0 0;
  border: 1px solid #c6aa76;
}
.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}
.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox.checkbox-inline {
  margin-top: 0;
}
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.radio + .radio, .checkbox + .checkbox{margin-top: 10px;}
.radio {
  padding-left: 20px;
}
.radio label {
  display: inline-block;
  padding-left: 12px;
  position: relative;
}

.radio label::before {
  background-color: #fff;
  border: 1px solid #cecece;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 22px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: border 0.15s ease-in-out 0s;
  width: 22px;
}
.radio label::after {
  border-radius: 50%;
  display: inline-block;
  height: 11px;
  left: 3px;
  margin-left: -20px;
  position: absolute;
  top: 3px;
  transform: scale(0, 0);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33) 0s;
  width: 11px;
}
.radio input[type="radio"] {
  opacity: 0;
}
.radio input[type="radio"]:focus + label::before {
  outline-offset: -2px;
}
.radio input[type="radio"]:checked + label::before {
	border-width: 6px;
	border-color: #c6aa76;
  transform: scale(1, 1);
}
.radio input[type="radio"]:checked + label::after {
  transform: scale(1, 1);
}
.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}
.radio.radio-inline {
  margin-top: 0;
}
.radio-primary input[type="radio"] + label::after {
  background-color: #428bca;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #428bca;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #428bca;
}
.radio-danger input[type="radio"] + label::after {
  background-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d9534f;
}
.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de;
}
.radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e;
}
.radio-success input[type="radio"] + label::after {
  background-color: #c6aa76;
}
.radio-success input[type="radio"]:checked + label::before {
  border-color: #c6aa76;
}
.radio-success input[type="radio"]:checked + label::after {
  background-color: #c6aa76;
}

// .add-pro-popup
@media only screen and (max-width:1184px) {
  .add-pro-popup .table .minwidth{width:130px;display: inline-block;}
}
@media only screen and (max-width:1024px) {
.add-pro-popup .modal-body{padding:80px 15px 0 15px;}
.add-pro-popup .close{top:15px;}
.add-pro-popup .table tr th{padding:10px;}
.add-pro-popup .table tbody tr td{padding: 20px 10px;}

.dashboard-list-content p br{display:none;}
}
@media only screen and (max-width:1023px) {
.add-pro-popup .table-responsive {overflow-y: scroll !important;max-height:550px}
.add-pro-popup .table{background: transparent}

.add-pro-popup .close{top:0;}
.add-pro-popup .table tr th,
.add-pro-popup .table tr td{padding:10px;}

}

@media only screen and (max-width:768px) {
  .table-responsive.filter-product{  width: 61%;}
  .add-pro-popup h3{text-align: left;}
  .add-pro-popup h3 .btn {margin:0 !important;}
  .filter-link {width: 160px !important;}
  .filter-link a {padding: 5px 10px !important;}
  .db-box-edit-container .edit_carebox,
  .db-box-edit-container .feedback-col {width:100% !important;}
  .db-box-edit-container .col-sm-4{padding:0;width:100%;}
  .filters-col .panel-default > .panel-heading{ width: 160px;}
  .modal.patient-search-popup .modal-content{width:100%;}

  .dashboard-list > li strong {margin:0 10px 0 5px;}
}

@media only screen and (max-width:767px) {
  .content-area {padding:40px 15px 30px 15px;
  }
.popover{left:inherit !important;right:10px !important}
.feedback-clr{background:transparent}

  .welcome_login{display:none;}
  .container > .navbar-header{margin: 0;}
  #nav-toggle,
  .patient-mobile-nav .nav{display: block;}
  .p-account-link{ text-align: center !important}
  header.navbar .container {padding: 0 15px;}
	header.dashboard-header .navbar-header {margin:0;}
  .table-responsive.filter-product{  width: 61%;}

	.modal-open,
  .modal-open .navbar-fixed-top,
	.modal-open .navbar-fixed-bottom{margin-right:0;}

  .modal-dialog{width:100%; padding:15px;}
  .modal-body {padding:20px 0;}

  .pharmacy_message_popup .modal-body,
  .care_box_added_popup .modal-body,
  .box_product_popup .modal-body,
  .care_plan_box_popup .modal-body,
  .box_product_delete_confirm .modal-body{padding:15px 20px;}
  .pharmacy_message_popup .close,
  .care_box_added_popup .close,
  .box_product_popup .close,
  .care_plan_box_popup .close,
  .box_product_delete_confirm .close{margin-top: -7px;}

  .pause-box-popup .modal-dialog{background: #f0f0f0;}
  .pause-box-popup .modal-content{box-shadow:none}
  .pause-box-popup .btn{padding:0;}

	.patient-search-popup .modal-dialog{width:96%; padding:10px;}
  .add-patient-popup .close {margin: -8px 10px 0 0;}
	.add-patient-popup .modal-dialog{width:96%;padding: 30px 0;}
	.add-patient-popup .modal-footer  {padding:15px 20px}

  .add-pro-popup .modal-body{padding:65px 15px 0 15px;}
  .add-pro-popup .close {top: 15px;}
  .add-pro-popup .modal-dialog {width: 100% !important;max-width: 100%;}
  .add-pro-popup .vertical-alignment-helper,
  .add-pro-popup .vertical-align-center{display:block;}
  .add-pro-popup h3{margin-bottom:0;}
  .add-pro-popup h3 .button-set{position:relative;top:inherit;margin:10px 0;}
}

// style.css
.add-pro-popup
{
  background: rgba(192, 192, 192, 0.95);
}
.patient-search-popup{
    background: rgba(14,36,57,.98);
}
.add-pro-popup .modal-content {
    border: 0;
    background: none;
    color: #fff;
    box-shadow: 0 0;
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
}
.add-pro-popup .modal-body{padding:80px 4% 0 4%;}
.add-pro-popup .close{
	border-width: 4px;
  border-color: #c6aa76;
  color: #c6aa76;
  opacity: 0.98;
  line-height: 2px;
  font-size: 67px;
  height: 35px;
  margin: 0;
  position: absolute;
  right: 50px;
  top: 35px;
  width: 35px;
}
.add-pro-popup .close span{
  font-size:34px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 30px !important;
  text-shadow: none;
}
.add-pro-popup .modal-dialog{padding:0;}
.add-pro-popup .table .minwidth{width:12%;}
.add-pro-popup .table tr th{padding:10px;}
.add-pro-popup .table tbody tr td{padding: 20px 10px;}
.add-pro-popup .table thead > tr > th{background:#0e2438;}
.add-pro-popup .table tbody > tr:hover{background:none;}
.add-pro-popup h3 {
    text-align: center;
    font-weight: 700;
    margin: 0 0 30px;
	position:relative;
}

.add-pro-popup h3 .button-set {
	position:absolute;
	right:0;
	top:-10px;
}
.add-pro-popup h3 .btn {
    margin: 0 0 0 10px;
    min-width: 100px;
}
.add-pro-popup .ui-switchbutton-default {
    background: #0e2439;
    border: 1px solid #d9d9d9;
    height: 30px;
    padding: 0;
}
.add-pro-popup .table  tr td{border-color:#2d4052;}
.add-pro-popup .ui-switchbutton-ios5 .ui-switchbutton-handle {
    background: #fff none repeat scroll 0 0;
    border: 2px solid #0e2439;
    border-radius: 50%;
    box-shadow: none !important;
    height: 28px;
    width: 28px !important;
}

.add-pro-popup .ui-switchbutton-ios5.ui-state-active .ui-switchbutton-handle {
    background: #c6aa76 none repeat scroll 0 0;
}
.add-pro-popup .ui-switchbutton-default label.ui-switchbutton-disabled,
.add-pro-popup .ui-switchbutton-default label.ui-switchbutton-enabled{color:#fff;}

.add-pro-popup .form-control{background:none;color:#fff}

.add-pro-popup .table tbody > tr:hover{box-shadow:none;}
.add-pro-popup .table tbody > tr:last-child > td {
    border-bottom-color: #293c4d;
}
.add-pro-popup .bootstrap-select > .dropdown-toggle {
  background: #0e2438;
  border-color: #fff;
	color:#fff;
	padding-left: 8px;
}
.add-pro-popup .bootstrap-select > .dropdown-toggle .caret{
  border-top-color:#c6aa76;
  right: 8px;
}

.DatePicker, .instruction {
  .form-control {
    box-shadow: none !important;
    border-top: 0;
    border-right: 0;
    border-left: 0;
  }
}

.table-title {
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.82;
  letter-spacing: normal;
  color: #0e2439;
  text-align: left;
  padding: 6px 0;
}

.fade.modal-backdrop.in {
  background-color: transparent;
}