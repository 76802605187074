@font-face {
  font-family: "proxima-nova";
  src: url(fonts/proximanova-regular.woff);
  font-weight: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url(fonts/proximanova-bold.woff);
  font-weight: bold;
}

@font-face {
  font-family: "proxima-nova";
  src: url(fonts/proximanova-light.woff);
  font-weight: lighter;
}

$body-bg: rgb(250, 250, 250);

$font-size-base: 16px !default;
$font-size-large: 18px !default;

$brand-primary: #c6aa76;

$text-color: #4e5052;
$headings-color: #0e2439;

$font-family-sans-serif: "proxima-nova", sans-serif;
$font-family-serif: "proxima-nova", sans-serif;

$btn-default-color: #c6aa76;
$btn-default-bg: rgba(255, 255, 255, 0);
$btn-default-border: #c6aa76;

$btn-border-radius-base: 0;
$btn-border-radius-large: 0;
$btn-border-radius-small: 0;

$border-radius-large: 0;

$input-border-radius: 2px;
$input-border-radius-large: 2px;
$input-border-focus: #c6aa76;
$input-color: #4e5052;

$border-radius-base: 0;

// pagination

$pagination-active-bg: #13243d;
$pagination-active-border: #13243d;
$pagination-color: #4e5052;

// tables

$table-bg-hover: #f0f0f0;

// Tabs

$nav-tabs-border-color: #fff;
$nav-tabs-justified-link-border-color: #fff;
$nav-tabs-active-link-hover-bg: #fff;
$nav-tabs-active-link-hover-border-color: #fff;
$nav-tabs-active-link-hover-color: #13447c;

// Modal

$modal-content-bg: #f0f0f0;
$modal-header-border-color: #f0f0f0;
$modal-footer-border-color: #f0f0f0;
$modal-md: 500px !default;

// Close
$close-color: $brand-primary;

// react-select
$select-input-border-focus: #c6aa76;
