.LoginPage {
  z-index: -20;

  .dashboard-login-container {
    padding: 80px 0 50px;
    min-height: calc(100vh - 200px);
    position: relative;
    z-index: 0;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: -40px;
      left: 0;
      background: #527085;
      opacity: 0.85;
      z-index: -10;
    }

    .dashboard-login {
      z-index: 20;
    }

    .form-group-lg {
      margin-top: 15px;

      label {
        float: left;
        margin-bottom: 5px;
      }
    }

    .button-set {
      padding-top: 40px;
      font-size: 16px;

      .subset {
        width: 50%;
        display: inline-block;

        &.left {
          text-align: right;
          padding-right: 7px;
        }

        &.right {
          border-left: 1px #343434 solid;
          text-align: left;
          padding-left: 7px;
        }

        .link {
          cursor: pointer;
          color: #343434;
        }
      }
    }
  }

  @media screen and (max-width: 461px) {
    .dashboard-login-container {
      padding: 0px;
      .dashboard-login {
        padding: 100px 50px 40px;
      }
    }
  }
}

.Alert-content {
  text-align: left;
  padding: 0 30px;
  margin-top: -40px;

  h4 {
    font-weight: bold;
  }

  span.required {
    font-size: 12px;
    color: #0e2439;
  }

  label {
    font-weight: normal;
    color: #0e2439;
    padding-left: 10px;
  }

  .failed {
    position: absolute;
    bottom: 30px;
    color: rgba(209, 0, 0, 0.83);
    font-size: 20px;
  }
}

// WARNING: Styles moved from Legrand without changes

.dashboard-login-container {
  background-size: cover;
}
.dashboard-login {
  background: #f0f0f0 none repeat scroll 0 0;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.6);
  color: #fff;
  margin: 0 auto;
  max-width: 460px;
  padding: 35px 50px 40px;
  text-align: center;
  border-top: 10px solid #c6aa76;
}
.dashboard-login h1 {
  margin: 28px 0 15px;
  color: #fff;
  font-size: 40px;
}
.dashboard-login .form-group {
  text-align: left;
}

div.dashboard-f-link {
  margin: 5px 0 10px;
}
.dashboard-f-link a {
  color: #c5a976;
}

.dashboard-login .signinbtn .btn,
.dashboard-login .becomephysician .btn,
.dashboard-login .forgotpasslink .link {
  font-weight: bold;
}
.dashboard-login .signinbtn,
.dashboard-login .becomephysician {
  margin-top: 40px;
}
.dashboard-login .forgotpasslink {
  margin-top: 25px;
  font-size: 18px;
}
.dashboard-login .becomephysician {
  margin-bottom: 20px;
}
