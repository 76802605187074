.Paper {
  position: relative;
  width: 210mm;
  min-height: 297mm;
  border: 1px #c0c0c0 solid;
  box-sizing: border-box;
  margin: auto;
  padding: 10px 30px;

  table {
    margin-top: 40px;
    margin-bottom: 20px;

    tbody {
      border: none;

      tr {
        td, th {
          border-top: none !important;
          border-bottom: 2px #d9d9d9 solid;
        }
      }
    }
  }
}

@media print {
  .Paper {
    border: none;
    -webkit-print-color-adjust: exact;

    table {
      thead {
        tr, td, th {
          background-color: #4e5052 !important;
          color: #fff !important;
          border: none;
        }
      }
    }
  }

  .col-md-1 {width:8%;  float:left;}
  .col-md-2 {width:16%; float:left;}
  .col-md-3 {width:25%; float:left;}
  .col-md-4 {width:33%; float:left;}
  .col-md-5 {width:42%; float:left;}
  .col-md-6 {width:50%; float:left;}
  .col-md-7 {width:58%; float:left;}
  .col-md-8 {width:66%; float:left;}
  .col-md-9 {width:75%; float:left;}
  .col-md-10{width:83%; float:left;}
  .col-md-11{width:92%; float:left;}
  .col-md-12{width:100%; float:left;}

  @page {
    size: A4;

    :left {
      margin-left: 0;
    }

    :right {
      margin-left: 0;
    }
  }
}
